<template>
    <v-row>
        <v-col cols="12" lg="6" xl="4" order="3" order-xl="1">
            <v-card height="100%">
                <v-subheader class="text-uppercase primary--text pt-4">{{ $t('settings.companyGroup.groupEditor') }}</v-subheader>
                <v-card-text>
                    <v-switch
                        v-model="useDepartmentInProcessingActivity"
                        color="primary"
                        :label="$t('settings.companyGroup.useDepartmentInProcessingActivity.label')"
                        :hint="$t('settings.companyGroup.useDepartmentInProcessingActivity.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useDepartmentInProcessingActivity', 'save settings'])"
                        :loading="$wait.is('save useDepartmentInProcessingActivity')"
                    ></v-switch>
                    <v-switch
                        v-model="useDomainInProcessingActivity"
                        color="primary"
                        :label="$t('settings.companyGroup.useDomainInProcessingActivity.label')"
                        :hint="$t('settings.companyGroup.useDomainInProcessingActivity.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useDomainInProcessingActivity', 'save settings'])"
                        :loading="$wait.is('save useDomainInProcessingActivity')"
                    ></v-switch>
                    <v-switch
                        v-model="useDataFields"
                        color="primary"
                        :label="$t('settings.companyGroup.useDataFields.label')"
                        :hint="$t('settings.companyGroup.useDataFields.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useDataFields', 'save settings'])"
                        :loading="$wait.is('save useDataFields')"
                    ></v-switch>
                    <v-switch
                        v-model="useDataCategories"
                        color="primary"
                        :label="$t('settings.companyGroup.useDataCategories.label')"
                        :hint="$t('settings.companyGroup.useDataCategories.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useDataCategories', 'save settings'])"
                        :loading="$wait.is('save useDataCategories')"
                    ></v-switch>
                    <v-select
                        v-model="enabledUserFreetextInputFields"
                        multiple
                        :label="$t('settings.companyGroup.enabledUserFreetextInputFields.label')"
                        :hint="$t('settings.companyGroup.enabledUserFreetextInputFields.hint')"
                        :items="enabledUserFreetextInputFieldItems"
                        item-value="alias"
                        item-text="title"
                        clearable
                        persistent-hint
                        chips
                        small-chips
                        :disabled="$wait.is(['fetch group settings', 'save enabledUserFreetextInputFields', 'save settings'])"
                        :loading="$wait.is('save enabledUserFreetextInputFields')"
                        class="mt-8"
                    />
                    <v-switch
                        v-model="useCompanyTomInRecord"
                        color="primary"
                        :label="$t('settings.companyGroup.useCompanyTomInRecord.label')"
                        :hint="$t('settings.companyGroup.useCompanyTomInRecord.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useCompanyTomInRecord', 'save settings'])"
                        :loading="$wait.is('save useCompanyTomInRecord')"
                    ></v-switch>
                    <v-switch
                        v-model="useToolTomInRecord"
                        color="primary"
                        :label="$t('settings.companyGroup.useToolTomInRecord.label')"
                        :hint="$t('settings.companyGroup.useToolTomInRecord.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useToolTomInRecord', 'save settings'])"
                        :loading="$wait.is('save useToolTomInRecord')"
                    ></v-switch>
                    <v-switch
                        v-model="useBlacklistInfoTable"
                        color="primary"
                        :label="$t('settings.companyGroup.useBlacklistInfoTable.label')"
                        :hint="$t('settings.companyGroup.useBlacklistInfoTable.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useBlacklistInfoTable', 'save settings'])"
                        :loading="$wait.is('save useBlacklistInfoTable')"
                    ></v-switch>
                    <v-select
                        v-model="blacklistQuestionnaire"
                        :label="$t('settings.companyGroup.blacklistQuestionnaire.label')"
                        :hint="$t('settings.companyGroup.blacklistQuestionnaire.hint')"
                        :items="blacklistQuestionnaireItems"
                        item-value="id"
                        item-text="title"
                        clearable
                        persistent-hint
                        :disabled="$wait.is(['fetch group settings', 'save blacklistQuestionnaire', 'save settings'])"
                        :loading="$wait.is('save blacklistQuestionnaire')"
                        class="mt-8"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" lg="6" xl="4" order="2">
            <v-card height="100%">
                <v-subheader class="text-uppercase primary--text pt-4">{{ $t('settings.companyGroup.groupWorkflow') }}</v-subheader>
                <v-card-text>
                    <v-alert v-if="!companyFeatureWorkflowEnabled" dense type="info" icon="mdi-information-outline" text>
                        <span class="caption">{{ $t('settings.companyGroup.useWorkflow.noLicense') }}</span>
                    </v-alert>
                    <v-switch
                        v-model="useWorkflow"
                        color="primary"
                        :label="$t('settings.companyGroup.useWorkflow.label')"
                        :hint="$t('settings.companyGroup.useWorkflow.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="!companyFeatureWorkflowEnabled || $wait.is(['fetch group settings', 'save useWorkflow', 'save settings'])"
                        :loading="$wait.is('save useWorkflow')"
                        @change="$validator.validate()"
                    ></v-switch>
                    <v-switch
                        v-model="allowUserToStartWorkflow"
                        color="primary"
                        :label="$t('settings.companyGroup.allowUserToStartWorkflow.label')"
                        :hint="$t('settings.companyGroup.allowUserToStartWorkflow.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="!companyFeatureWorkflowEnabled || $wait.is(['fetch group settings', 'save allowUserToStartWorkflow', 'save settings'])"
                        :loading="$wait.is('save allowUserToStartWorkflow')"
                    ></v-switch>
                    <v-select
                        v-model="workflowDisableDraftForce"
                        :label="$t('settings.companyGroup.workflowDisableDraftForce.label')"
                        :hint="$t('settings.companyGroup.workflowDisableDraftForce.hint')"
                        :items="workflowDisableDraftForceItems"
                        item-value="id"
                        item-text="title"
                        clearable
                        persistent-hint
                        :disabled="!companyFeatureWorkflowEnabled || $wait.is(['fetch group settings', 'save workflowDisableDraftForce', 'save settings'])"
                        :loading="$wait.is('save workflowDisableDraftForce')"
                        class="mt-8"
                    />
                    <v-text-field
                        :label="$t('settings.companyGroup.workflowConsultantEmail.label')"
                        :hint="$t('settings.companyGroup.workflowConsultantEmail.hint')"
                        v-model="workflowConsultantEmail"
                        persistent-hint
                        :error-messages="errors.collect('companyGroup.workflowConsultantEmail')"
                        data-vv-name="workflowConsultantEmail"
                        data-vv-scope="companyGroup"
                        v-validate="{required: !!useWorkflow === true, regex: /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/}"
                        :required="!!useWorkflow === true"
                        :disabled="!companyFeatureWorkflowEnabled || $wait.is(['fetch group settings', 'save workflowConsultantEmail', 'save settings'])"
                        :loading="$wait.is('save workflowConsultantEmail')"
                        @keyup="$validator.validate()"
                        @blur="$validator.validate()"
                        @focus="$validator.validate()"
                        class="mt-8">
                        <template v-slot:append>
                            <v-btn color="success" class="mb-1" small @click="submitWorkflowConsultantEmail()" :disabled="!workflowConsultantEmailSubmitAble" :loading="$wait.is(['fetch group settings', 'save workflowConsultantEmail', 'save settings'])">{{ $t('save') }}</v-btn>
                        </template>
                    </v-text-field>
                    <v-switch
                        v-model="useAuthorityReportRelevance"
                        color="primary"
                        :label="$t('settings.companyGroup.useAuthorityReportRelevance.label')"
                        :hint="$t('settings.companyGroup.useAuthorityReportRelevance.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useAuthorityReportRelevance', 'save settings'])"
                        :loading="$wait.is('save useAuthorityReportRelevance')"
                    ></v-switch>
                    <v-select
                        v-model="resubmissionRecipients"
                        multiple
                        :label="$t('settings.companyGroup.resubmissionRecipients.label')"
                        :hint="$t('settings.companyGroup.resubmissionRecipients.hint')"
                        :items="resubmissionRecipientItems"
                        item-value="alias"
                        item-text="title"
                        clearable
                        persistent-hint
                        chips
                        small-chips
                        :disabled="$wait.is(['fetch group settings', 'save resubmissionRecipients', 'save settings'])"
                        :loading="$wait.is('save resubmissionRecipients')"
                        class="mt-8"
                    />
                </v-card-text>
                <v-alert
                    prominent
                    color="primary"
                    dark
                    icon="mdi-email-edit-outline"
                    >
                    <v-row align="center">
                        <v-col class="grow">
                            {{ $t('settings.companyGroupNotification.workflowBannerText') }}
                        </v-col>
                        <v-col class="shrink">
                            <v-btn
                                @click="$router.push({ name:'SettingsCompanyGroupNotification' })"
                                text2
                                color="white"
                                outlined
                            >
                                {{ $t('settings.companyGroupNotification.manageNotifications') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card>
        </v-col>
        <v-col cols="12" lg="6" xl="4" order="1" order-xl="3">
            <v-card height="100%">
                <v-subheader class="text-uppercase primary--text pt-4">{{ $t('settings.companyGroup.groupGeneral') }}</v-subheader>
                <v-card-text>
                    <v-switch
                        v-model="useOfLeaMaster"
                        color="primary"
                        :label="$t('settings.companyGroup.useOfLeaMaster.label')"
                        :hint="$t('settings.companyGroup.useOfLeaMaster.hint')"
                        persistent-hint
                        class="mt-0"
                        :disabled="$wait.is(['fetch group settings', 'save useOfLeaMaster', 'save settings'])"
                        :loading="$wait.is('save useOfLeaMaster')"
                    ></v-switch>
                    <v-switch
                        v-model="useVendor"
                        color="primary"
                        :label="$t('settings.companyGroup.useVendor.label')"
                        :hint="$t('settings.companyGroup.useVendor.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="$wait.is(['fetch group settings', 'save useVendor', 'save settings'])"
                        :loading="$wait.is('save useVendor')"
                    ></v-switch>
                    <v-switch
                        v-model="useDomainGrouping"
                        color="primary"
                        :label="$t('settings.companyGroup.useDomainGrouping.label')"
                        :hint="$t('settings.companyGroup.useDomainGrouping.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="!useDomainInProcessingActivity || $wait.is(['fetch group settings', 'save useDomainGrouping', 'save settings'])"
                        :loading="$wait.is('save useDomainGrouping')"
                    ></v-switch>
                    <v-switch
                        v-model="domainGroupedProcessingActivityList"
                        color="primary"
                        :label="$t('settings.companyGroup.domainGroupedProcessingActivityList.label')"
                        :hint="$t('settings.companyGroup.domainGroupedProcessingActivityList.hint')"
                        persistent-hint
                        class="mt-8"
                        :disabled="!useDomainInProcessingActivity || $wait.is(['fetch group settings', 'save domainGroupedProcessingActivityList', 'save settings'])"
                        :loading="$wait.is('save domainGroupedProcessingActivityList')"
                    ></v-switch>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hasFeature } from '@/utils/auth';
import { waitFor } from 'vue-wait';

export default {
    name: 'CompanyGroupSettings',
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    data() {
        return {
            tmpWorkflowConsultantEmail: null
        };
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            getCompanyGroupSettings: 'companyGroupSettings/get'
        }),
        companyFeatureWorkflowEnabled () {
            return hasFeature('WORKFLOW');
        },
        useOfLeaMaster: {
            get() {
                return this.getCompanyGroupSettings('useOfLeaMaster');
            },
            set(val) {
                this.setCompanyGroupSettings('useOfLeaMaster', val);
            },
        },
        useVendor: {
            get() {
                return this.getCompanyGroupSettings('useVendor');
            },
            set(val) {
                this.setCompanyGroupSettings('useVendor', val);
            },
        },
        useDepartmentInProcessingActivity: {
            get() {
                return this.getCompanyGroupSettings('useDepartmentInProcessingActivity');
            },
            set(val) {
                this.setCompanyGroupSettings('useDepartmentInProcessingActivity', val);
            },
        },
        useDomainInProcessingActivity: {
            get() {
                return this.getCompanyGroupSettings('useDomainInProcessingActivity');
            },
            set(val) {
                this.setCompanyGroupSettings('useDomainInProcessingActivity', val);
            },
        },
        useDomainGrouping: {
            get() {
                return this.getCompanyGroupSettings('useDomainGrouping');
            },
            set(val) {
                this.setCompanyGroupSettings('useDomainGrouping', val);
            },
        },
        domainGroupedProcessingActivityList: {
            get() {
                return this.getCompanyGroupSettings('domainGroupedProcessingActivityList');
            },
            set(val) {
                this.setCompanyGroupSettings('domainGroupedProcessingActivityList', val);
            },
        },
        useWorkflow: {
            get() {
                return this.getCompanyGroupSettings('useWorkflow');
            },
            set(val) {
                this.setCompanyGroupSettings('useWorkflow', val);
            },
        },
        allowUserToStartWorkflow: {
            get() {
                return this.getCompanyGroupSettings('allowUserToStartWorkflow');
            },
            set(val) {
                this.setCompanyGroupSettings('allowUserToStartWorkflow', val);
            },
        },
        useAuthorityReportRelevance: {
            get() {
                return this.getCompanyGroupSettings('useAuthorityReportRelevance');
            },
            set(val) {
                this.setCompanyGroupSettings('useAuthorityReportRelevance', val);
            },
        },
        useDataFields: {
            get() {
                return this.getCompanyGroupSettings('useDataFields');
            },
            set(val) {
                this.setCompanyGroupSettings('useDataFields', val);
            },
        },
        useDataCategories: {
            get() {
                return this.getCompanyGroupSettings('useDataCategories');
            },
            set(val) {
                this.setCompanyGroupSettings('useDataCategories', val);
            },
        },
        workflowConsultantEmailSubmitAble() {
            if (this.getCompanyGroupSettings('workflowConsultantEmail') === this.tmpWorkflowConsultantEmail || 
                (
                    this.workflowConsultantEmail === this.getCompanyGroupSettings('workflowConsultantEmail')
                    && this.tmpWorkflowConsultantEmail !== null
                    && this.tmpWorkflowConsultantEmail !== ''
                )
            ) {
                return false;
            }

            return !this.workflowConsultantEmailError();
        },
        workflowConsultantEmail: {
            get() {
                return this.tmpWorkflowConsultantEmail !== null ? this.tmpWorkflowConsultantEmail : this.getCompanyGroupSettings('workflowConsultantEmail');
            },
            set(val) {
                this.tmpWorkflowConsultantEmail = val;
            }
        },
        resubmissionRecipients: {
            get() {
                return this.getCompanyGroupSettings('resubmissionRecipients') ? this.getCompanyGroupSettings('resubmissionRecipients') : [];
            },
            set(val) {
                this.setCompanyGroupSettings('resubmissionRecipients', val);
            },
        },
        resubmissionRecipientItems () {
            return [
                {
                    alias: 'recordEditors',
                    title: this.$t('settings.companyGroup.resubmissionRecipients.items.recordEditors')
                },
                {
                    alias: 'recordOwners',
                    title: this.$t('settings.companyGroup.resubmissionRecipients.items.recordOwners')
                },
                {
                    alias: 'recordCoordinators',
                    title: this.$t('settings.companyGroup.resubmissionRecipients.items.recordCoordinators')
                },
                {
                    alias: 'groupConsultants',
                    title: this.$t('settings.companyGroup.resubmissionRecipients.items.groupConsultants')
                },
                {
                    alias: 'companyDpo',
                    title: this.$t('settings.companyGroup.resubmissionRecipients.items.companyDpo')
                }
            ];
        },
        enabledUserFreetextInputFieldItems () {
            return [
                {
                    alias: 'internalKeywords',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.internalKeywords')
                },
                {
                    alias: 'tools',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.tools')
                },
                {
                    alias: 'groupsOfPersons',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.groupsOfPersons')
                },
                {
                    alias: 'personalData',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.personalData')
                },
                {
                    alias: 'personalDataCategory',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.personalDataCategory')
                },
                {
                    alias: 'nationalDataProtectionLaw',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.nationalDataProtectionLaw')
                },
                {
                    alias: 'deletionPractise',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.deletionPractise')
                },
                {
                    alias: 'internalRecipientCategory',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.internalRecipientCategory')
                },
                {
                    alias: 'appropriateSafeguards',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.appropriateSafeguards')
                },
                {
                    alias: 'externalRecipientCategory',
                    title: this.$t('settings.companyGroup.enabledUserFreetextInputFields.items.externalRecipientCategory')
                },
            ];
        },
        enabledUserFreetextInputFields: {
            get() {
                return this.getCompanyGroupSettings('enabledUserFreetextInputFields') ? this.getCompanyGroupSettings('enabledUserFreetextInputFields') : [];
            },
            set(val) {
                this.setCompanyGroupSettings('enabledUserFreetextInputFields', val);
            },
        },
        useCompanyTomInRecord: {
            get() {
                return this.getCompanyGroupSettings('useCompanyTomInRecord');
            },
            set(val) {
                this.setCompanyGroupSettings('useCompanyTomInRecord', val);
            },
        },
        useToolTomInRecord: {
            get() {
                return this.getCompanyGroupSettings('useToolTomInRecord');
            },
            set(val) {
                this.setCompanyGroupSettings('useToolTomInRecord', val);
            },
        },
        useBlacklistInfoTable: {
            get() {
                return this.getCompanyGroupSettings('useBlacklistInfoTable');
            },
            set(val) {
                this.setCompanyGroupSettings('useBlacklistInfoTable', val);
            },
        },
        blacklistQuestionnaireItems () {
            return this.getCompanyGroupSettings('blacklistQuestionnaireItems') || [];
        },
        blacklistQuestionnaire: {
            get() {
                return this.getCompanyGroupSettings('blacklistQuestionnaire');
            },
            set(val) {
                this.setCompanyGroupSettings('blacklistQuestionnaire', val);
            },
        },
        workflowDisableDraftForceItems () {
            return [
                {
                    id: 'ROLE_EDITOR',
                    title: this.$t('settings.companyGroup.workflowDisableDraftForce.items.ROLE_EDITOR')
                },
                {
                    id: 'ROLE_TENANT_EDITOR',
                    title: this.$t('settings.companyGroup.workflowDisableDraftForce.items.ROLE_TENANT_EDITOR')
                }
            ];
        },
        workflowDisableDraftForce: {
            get() {
                return this.getCompanyGroupSettings('workflowDisableDraftForce');
            },
            set(val) {
                this.setCompanyGroupSettings('workflowDisableDraftForce', val);
            },
        },
    },
    methods: {
        ...mapActions({
            fetchSettings: 'companyGroupSettings/fetch',
            saveSettings: 'companyGroupSettings/save'
        }),
        setCompanyGroupSettings (alias, val) {
            this.$wait.start('save settings');
            this.$wait.start('save ' + alias);
            let options = {};
            options[alias] = val;
            this.saveSettings(options).then(() => {
                this.$wait.end('save settings');
                this.$wait.end('save ' + alias);
            });
        },
        workflowConsultantEmailError () {
            const errors = this.$validator.errors.collect();
            return !!(Object.keys(errors).length && errors['workflowConsultantEmail']) === true;
        },
        submitWorkflowConsultantEmail() {
            if (this.workflowConsultantEmailSubmitAble) {
                this.saveWorkflowConsultantEmail();
            }
        },
        saveWorkflowConsultantEmail: waitFor('saveWorkflowConsultantEmail', async function() {
            if (this.workflowConsultantEmailSubmitAble) {
                this.$wait.start('save settings');
                this.$wait.start('save workflowConsultantEmail');
                this.saveSettings({
                    workflowConsultantEmail: this.workflowConsultantEmail === this.getCompanyGroupSettings('workflowConsultantEmail') ? this.tmpWorkflowConsultantEmail : this.workflowConsultantEmail
                }).then(() => {
                    this.$wait.end('save settings');
                    this.$wait.end('save workflowConsultantEmail');
                });
            }
        }),
    },
    mounted () {
        this.$wait.start('fetch group settings');
        this.fetchSettings().then(() => {
            this.tmpWorkflowConsultantEmail = this.getCompanyGroupSettings('workflowConsultantEmail');
            this.$wait.end('fetch group settings');
        });
    }
};
</script>
